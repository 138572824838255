:root {
  font-size: 16px;

  --color-primary: #666cff;
  --color-primary-light: #e8eaf6;
  --color-primary-dark: #002654;
  --color-secondary: #db0024;
  --color-secondary-light: #f92f2f1a;
  --color-secondary-dark: #ce1126;
  --color-disable: #a6a5a5;
  --color-disable-light: #dedede;

  --color-text: #636578;
  --color-text-light-1: #888a98;
  --color-text-light-2: #bbbcc4;

  --color-bg-0: #f7f7f9;
  --color-bg-1: #fff;
  --color-bg-2: #eaeaec;

  --color-border: #eaeaec;

  --color-binary: #f2f2f2;
  --color-white: white;
  --color-black: black;
  --color-error: #ef5350;
  --color-error-light: #f92f2f1a;
  --color-light-gray: #dedede;

  --color-scroll-bar: #bbb;
  --color-scroll-bar-hovered: #999;

  --color-sttc: #8d6e63;

  --font-size-bigger: 3rem; /* 48px */
  --font-size-big-2: 2.5rem; /* 40px */
  --font-size-big-1: 2rem; /* 32px */
  --font-size-big: 1.5rem; /* 24px */
  --font-size-normal: 1rem; /* 16px */
  --font-size-normal-2: 1.25rem; /* 20px */
  --font-size-small-1: 0.875rem; /* 14px */
  --font-size-small-2: 0.625rem; /* 10px */

  --font-family-primary: "Montserrat";
  --font-family-secondary: "Open Sans";

  --font-weight-thin: 100;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-semibold: 600;
  --font-weight-bold: 800;

  --width-side-menu: 16rem;
  --height-nav-bar: 3.125rem;
  --border-radius-0: 0.5rem;

  --shadow-box: 0 0.125rem 0.625rem 0 rgba(76, 78, 100, 0.22);
}

/* For Testing Purpose */
.bg-red {
  background-color: red;
}
.bg-blue {
  background-color: blue;
}
.bg-green {
  background-color: green;
}

.elevated-card {
  background-color: var(--color-white);
  padding: 1.6rem;
  box-shadow: var(--shadow-box);
  border-radius: var(--border-radius-0);
}

.elevated-card-tight-vertical {
  background-color: var(--color-white);
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  box-shadow: var(--shadow-box);
  border-radius: var(--border-radius-0);
}

.elevated-card-no-padding {
  background-color: var(--color-white);
  box-shadow: var(--shadow-box);
  border-radius: var(--border-radius-0);
}

.vertical-scrollbar-wrapper:hover {
  overflow: auto;
}

.vertical-scrollbar-wrapper::-webkit-scrollbar {
  width: 3px;
}

.vertical-scrollbar-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--color-scroll-bar);
  border-radius: 5px;
}

.vertical-scrollbar-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-scroll-bar-hovered);
  width: 5px;
}

.vertical-scrollbar-wrapper::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}

.horizontal-scrollbar-wrapper:hover {
  overflow: auto;
}

.horizontal-scrollbar-wrapper::-webkit-scrollbar {
  height: 3px;
}

.horizontal-scrollbar-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--color-scroll-bar);
  border-radius: 5px;
}

.horizontal-scrollbar-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-scroll-bar-hovered);
  height: 5px;
}

.horizontal-scrollbar-wrapper::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}

@media print {
  @page {
    margin: 20mm; /* Adjust the value as needed */
  }

  /* Ensure that the content breaks correctly across pages */
  .printable-report > * {
    page-break-inside: avoid;
  }
}
